/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/themes/dark.scss";
@import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.tomorrow {
  color: var(--ion-color-warning);
}
.dayAftertomorrow {
  color: var(--ion-color-success);
}
.today {
  color: var(--ion-color-danger);
}
.split-pane-visible > .split-pane-side {
  min-width: 200px !important;
  max-width: 200px !important;
}

ion-select-popover ion-item {
  font-size: 12px;
  --min-height: 30px;
  overflow: hidden;
}
.alertClass {
  .alert-wrapper {
    .alert-head {
      h2 {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .alertBtn {
    text-transform: none;
    font-weight: bold;
    background-color: var(--ion-color-warning);
    height: 30px;
    color: var(--ion-color-light);
    border-radius: 5px;
  }
  .alertBtnCancel {
    text-transform: none;
    color: var(--ion-color-warning);
    font-weight: bold;
    height: 30px;
    border: 2px solid var(--ion-color-warning);
    border-radius: 5px;
  }
}

.model {
  --width: 550px;
  --height: 500px;
  margin: auto;
  --background: #252525;
  --border-radius: 10px;
  --backdrop-opacity: 0.5;
  ion-toolbar {
    background: #141414;
  }
}
.theatreVerificationPopover {
  --width: 400px;
  --height: fit-content;
  --background: none !important;
}

.toast {
  margin-top: 20px;
}
@font-face {
  font-family: "open-sans";
  src: url("../src/assets/font/OpenSans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "open-sans-bold";
  src: url("../src/assets/font/OpenSans/OpenSans-SemiBold.ttf");
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 2px solid gray;
  background-clip: padding-box;
  border-radius: 3em;
  background-color: gray;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.loader {
  --spinner-color: #e81f29;
  font-family: open-sans;
  font-size: 16px;
  --backdrop-opacity: 0.8;
  .spinner {
    width: 100px;
  }
  .loading-wrapper {
    background: transparent !important;
    box-shadow: none !important;
  }
}
.ngx-datatable {
  width: fit-content;
  overflow-y: auto;
  margin: auto;
}
.table {
  padding-top: 10px;
  padding-left: 10px;
  max-height: 75vh;
  overflow-x: scroll;
  overflow-y: scroll;
  margin: auto;
}
ion-searchbar {
  .searchbar-input-container {
    input {
      font-size: 14px !important;
    }
  }
}
.table ngx-datatable.dark .datatable-body .datatable-body-cell {
  font-size: 11px !important;
}
.table ngx-datatable.dark .datatable-header .datatable-header-cell {
  font-size: 12px !important;
}

.gstAlertClass {
  --height: fit-content;
  --min-width: 280px;
}
ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      font-size: 12px;
    }
  }
}
ion-content,
ion-text,
ion-label,
ion-card,
ion-item,
ion-input,
ion-note,
ion-chip,
ion-select {
  font-family: open-sans !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
ion-title,
ion-card-title {
  font-family: open-sans !important;
  font-weight: bold;
}

.ngx-datatable.dark {
  box-shadow: none;
  background: hsl(0, 0%, 13%);
  border: 2px solid #494949;
  border-radius: 10px;
  color: #fff;
  font-size: 13px;

  .datatable-header {
    background: hsl(0, 0%, 15%);
    color: #848484;

    .datatable-header-cell {
      text-align: center;
      padding: 0.5rem 1.2rem;
      font-weight: bold;

      .datatable-header-cell-label {
        line-height: 24px;
      }
    }
  }
  .row-expanded {
    background-color: #303030;
    height: auto;
  }
  .datatable-row-detail {
    background: #303030;
  }
  .datatable-body {
    background: hsl(0, 0%, 13%);

    .datatable-body-row {
      border-top: 1px solid #282828;

      .datatable-body-cell {
        text-align: center;
        padding: 0.5rem 1.2rem;
        vertical-align: top;
      }

      &:hover {
        background: #424242;
        transition-property: background;
        transition-duration: 0.3s;
        transition-timing-function: linear;
      }

      &:focus {
        background-color: #363636;
      }

      &.active {
        background-color: #404040;
        color: #fff;
      }
    }
  }

  .datatable-footer {
    background: hsl(0, 0%, 15%);
    color: #bdbdbd;
    margin-top: -1px;

    .page-count {
      line-height: 50px;
      height: 40px;
      padding: 0 1.2rem;
    }

    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;

      ul {
        li {
          margin: 10px 0px;

          &:not(.disabled) {
            &.active a,
            &:hover a {
              background-color: #455066;
              font-weight: bold;
            }
          }
        }
      }

      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: #72809b;
      }

      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }

  .datatable-summary-row {
    .datatable-body-row {
      background-color: #14171f;

      &:hover {
        background-color: #14171f;
      }

      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}
